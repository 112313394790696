/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const categories = [
  {
    key: 'care',
    label: 'Care',
    amenities: [
      [
        { key: 'care-accessories', label: 'Accessories' },
        { key: 'care-baths', label: 'Baths' },
        { key: 'care-changing-mats', label: 'Changing mats' },
        { key: 'care-medicine', label: 'Medicine' },
        { key: 'care-nappy-disposal', label: 'Nappy disposal' },
        { key: 'care-reusable-nappies', label: 'Reusable nappies' },
        { key: 'care-teething-gear', label: 'Teething gear' },
        { key: 'care-thermometers', label: 'Thermometers' },
        { key: 'care-toiletries', label: 'Toiletries' },
        { key: 'care-towels', label: 'Towels' },
        { key: 'care-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'feed',
    label: 'Feed',
    amenities: [
      [
        { key: 'feed-accessories', label: 'Accessories' },
        { key: 'feed-bibs', label: 'Bibs' },
        { key: 'feed-bottles', label: 'Bottles' },
        { key: 'feed-breast-pumps', label: 'Breast pumps' },
        { key: 'feed-feeding-pillows', label: 'Feeding pillows' },
        { key: 'feed-highchairs', label: 'Highchairs' },
        { key: 'feed-sterilising', label: 'Sterilising' },
        { key: 'feed-storage', label: 'Storage' },
        { key: 'feed-tableware', label: 'Tableware' },
        { key: 'feed-weaning-gear', label: 'Weaning gear' },
        { key: 'feed-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'move',
    label: 'Move',
    ages: true,
    amenities: [
      [
        { key: 'move-accessories', label: 'Accessories' },
        { key: 'move-bouncers', label: 'Bouncers' },
        { key: 'move-buggies', label: 'Buggies' },
        { key: 'move-car-seats', label: 'Car seats' },
        { key: 'move-carriers', label: 'Carriers' },
        { key: 'move-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'organise',
    label: 'Organise',
    amenities: [
      [
        { key: 'organise-accessories', label: 'Accessories' },
        { key: 'organise-bags', label: 'Bags' },
        { key: 'organise-buggy-organisers', label: 'Buggy organisers' },
        { key: 'organise-drawers', label: 'Drawers' },
        { key: 'organise-shelves', label: 'Shelves' },
        { key: 'organise-storage', label: 'Storage' },
        { key: 'organise-wardrobes', label: 'Wardrobes' },
        { key: 'organise-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'play',
    label: 'Play',
    ages: true,
    amenities: [
      [
        { key: 'play-accessories', label: 'Accessories' },
        { key: 'play-baby-gyms', label: 'Baby gyms' },
        { key: 'play-books', label: 'Books' },
        { key: 'play-educational', label: 'Educational' },
        { key: 'play-imaginary-play', label: 'Imaginary play' },
        { key: 'play-musical', label: 'Musical' },
        { key: 'play-outdoor-play', label: 'Outdoor play' },
        { key: 'play-plastic-toys', label: 'Plastic toys' },
        { key: 'play-riding toys', label: 'Riding toys' },
        { key: 'play-wooden-toys', label: 'Wooden toys' },
        { key: 'play-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'read',
    label: 'Read',
    amenities: [
      [
        { key: 'read-books-for-children', label: 'Books for children' },
        { key: 'read-parenting-books', label: 'Parenting books' },
        { key: 'read-pregnancy-books', label: 'Pregnancy books' },
        { key: 'read-weaning-books', label: 'Weaning books' },
        { key: 'read-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'sleep',
    label: 'Sleep',
    amenities: [
      [
        { key: 'sleep-accessories', label: 'Accessories' },
        { key: 'sleep-bedding', label: 'Bedding' },
        { key: 'bedside-cots', label: 'Bedside cots' },
        { key: 'cot-beds', label: 'Cot beds' },
        { key: 'sleep-humidifiers', label: 'Humidifiers' },
        { key: 'sleep-lights', label: 'Lights' },
        { key: 'sleep-monitors', label: 'Monitors' },
        { key: 'sleep-pods', label: 'Sleep pods' },
        { key: 'sleep-sleeping-bags', label: 'Sleeping bags' },
        { key: 'sleep-travel-cots', label: 'Travel cots' },
        { key: 'sleep-white-noise', label: 'White noise' },
        { key: 'sleep-other', label: 'Other' },
      ],
    ],
  },
  {
    key: 'wear',
    label: 'Wear',
    ages: true,
    amenities: [
      [
        { key: 'wear-shoesizes-0-6m', label: '0-6m' },
        { key: 'wear-shoesizes-6-12m', label: '6-12m' },
        { key: 'wear-shoesizes-2-uk', label: '2 UK' },
        { key: 'wear-shoesizes-3-uk', label: '3 UK' },
        { key: 'wear-shoesizes-4-uk', label: '4 UK' },
        { key: 'wear-shoesizes-5-uk', label: '5 UK' },
        { key: 'wear-shoesizes-6-uk', label: '6 UK' },
        { key: 'wear-shoesizes-7-uk', label: '7 UK' },
        { key: 'wear-shoesizes-8-uk', label: '8 UK' },
        { key: 'wear-shoesizes-9-uk', label: '9 UK' },
        { key: 'wear-shoesizes-10-uk', label: '10 UK' },
        { key: 'wear-shoesizes-11-uk', label: '11 UK' },
        { key: 'wear-shoesizes-12-uk', label: '12 UK' },
        { key: 'wear-shoesizes-13-uk', label: '13 UK' },
        { key: 'wear-shoesizes-1-uk-youth', label: '1 UK (Youth)' },
        { key: 'wear-shoesizes-2-uk-youth', label: '2 UK (Youth)' },
        { key: 'wear-shoesizes-3-uk-youth', label: '3 UK (Youth)' },
        { key: 'wear-shoesizes-4-uk-youth', label: '4 UK (Youth)' },
        { key: 'wear-shoesizes-5-uk-youth', label: '5 UK (Youth)' },
      ],
      [
        { key: 'wear-maternity-6-adult', label: '6 Adult' },
        { key: 'wear-maternity-8-adult', label: '8 Adult' },
        { key: 'wear-maternity-10-adult', label: '10 Adult' },
        { key: 'wear-maternity-12-adult', label: '12 Adult' },
        { key: 'wear-maternity-14-adult', label: '14 Adult' },
        { key: 'wear-maternity-16-adult', label: '16 Adult' },
        { key: 'wear-maternity-18-adult', label: '18 Adult' },
        { key: 'wear-maternity-20-adult', label: '20 Adult' },
      ],
      [
        { key: 'wear-spring-summer', label: 'Spring/Summer' },
        { key: 'wear-autum-winter', label: 'Autum/Winter' },
      ],
      [
        { key: 'wear-boy', label: 'Boy' },
        { key: 'wear-girl', label: 'Girl' },
        { key: 'wear-unisex', label: 'Unisex' },
        { key: 'wear-maternity', label: 'Maternity' },
      ],
    ],
  },
  {
    key: 'everything',
    label: 'Everything',
    ages: true,
    amenities: [
      [
        { key: 'care-accessories', label: 'Accessories' },
        { key: 'care-baths', label: 'Baths' },
        { key: 'care-changing-mats', label: 'Changing mats' },
        { key: 'care-medicine', label: 'Medicine' },
        { key: 'care-nappy-disposal', label: 'Nappy disposal' },
        { key: 'care-reusable-nappies', label: 'Reusable nappies' },
        { key: 'care-teething-gear', label: 'Teething gear' },
        { key: 'care-thermometers', label: 'Thermometers' },
        { key: 'care-toiletries', label: 'Toiletries' },
        { key: 'care-towels', label: 'Towels' },
        { key: 'care-other', label: 'Other' },
      ],
      [
        { key: 'feed-accessories', label: 'Accessories' },
        { key: 'feed-bibs', label: 'Bibs' },
        { key: 'feed-bottles', label: 'Bottles' },
        { key: 'feed-breast-pumps', label: 'Breast pumps' },
        { key: 'feed-feeding-pillows', label: 'Feeding pillows' },
        { key: 'feed-highchairs', label: 'Highchairs' },
        { key: 'feed-sterilising', label: 'Sterilising' },
        { key: 'feed-storage', label: 'Storage' },
        { key: 'feed-tableware', label: 'Tableware' },
        { key: 'feed-weaning-gear', label: 'Weaning gear' },
        { key: 'feed-other', label: 'Other' },
      ],
      [
        { key: 'move-accessories', label: 'Accessories' },
        { key: 'move-bouncers', label: 'Bouncers' },
        { key: 'move-buggies', label: 'Buggies' },
        { key: 'move-car-seats', label: 'Car seats' },
        { key: 'move-carriers', label: 'Carriers' },
        { key: 'move-other', label: 'Other' },
      ],
      [
        { key: 'organise-accessories', label: 'Accessories' },
        { key: 'organise-bags', label: 'Bags' },
        { key: 'organise-buggy-organisers', label: 'Buggy organisers' },
        { key: 'organise-drawers', label: 'Drawers' },
        { key: 'organise-shelves', label: 'Shelves' },
        { key: 'organise-storage', label: 'Storage' },
        { key: 'organise-wardrobes', label: 'Wardrobes' },
        { key: 'organise-other', label: 'Other' },
      ],
      [
        { key: 'play-accessories', label: 'Accessories' },
        { key: 'play-baby-gyms', label: 'Baby gyms' },
        { key: 'play-books', label: 'Books' },
        { key: 'play-educational', label: 'Educational' },
        { key: 'play-imaginary-play', label: 'Imaginary play' },
        { key: 'play-musical', label: 'Musical' },
        { key: 'play-outdoor-play', label: 'Outdoor play' },
        { key: 'play-plastic-toys', label: 'Plastic toys' },
        { key: 'play-riding toys', label: 'Riding toys' },
        { key: 'play-wooden-toys', label: 'Wooden toys' },
        { key: 'play-other', label: 'Other' },
      ],
      [
        { key: 'read-books-for-children', label: 'Books for children' },
        { key: 'read-parenting-books', label: 'Parenting books' },
        { key: 'read-pregnancy-books', label: 'Pregnancy books' },
        { key: 'read-weaning-books', label: 'Weaning books' },
        { key: 'read-other', label: 'Other' },
      ],
      [
        { key: 'sleep-accessories', label: 'Accessories' },
        { key: 'sleep-bedding', label: 'Bedding' },
        { key: 'bedside-cots', label: 'Bedside cots' },
        { key: 'cot-beds', label: 'Cot beds' },
        { key: 'sleep-humidifiers', label: 'Humidifiers' },
        { key: 'sleep-lights', label: 'Lights' },
        { key: 'sleep-monitors', label: 'Monitors' },
        { key: 'sleep-pods', label: 'Sleep pods' },
        { key: 'sleep-sleeping-bags', label: 'Sleeping bags' },
        { key: 'sleep-travel-cots', label: 'Travel cots' },
        { key: 'sleep-white-noise', label: 'White noise' },
        { key: 'sleep-other', label: 'Other' },
      ],
      [
        { key: 'wear-boy', label: 'Boy' },
        { key: 'wear-girl', label: 'Girl' },
        { key: 'wear-unisex', label: 'Unisex' },
        { key: 'wear-maternity', label: 'Maternity' },
      ],
      [
        { key: 'wear-spring-summer', label: 'Spring/Summer' },
        { key: 'wear-autum-winter', label: 'Autum/Winter' },
      ],
      [
        { key: 'wear-maternity-6-adult', label: '6 Adult' },
        { key: 'wear-maternity-8-adult', label: '8 Adult' },
        { key: 'wear-maternity-10-adult', label: '10 Adult' },
        { key: 'wear-maternity-12-adult', label: '12 Adult' },
        { key: 'wear-maternity-14-adult', label: '14 Adult' },
        { key: 'wear-maternity-16-adult', label: '16 Adult' },
        { key: 'wear-maternity-18-adult', label: '18 Adult' },
        { key: 'wear-maternity-20-adult', label: '20 Adult' },
      ],
    ],
  },
];

export const ages = [
  { key: 'month-0-3', label: '0-3 months' },
  { key: 'month-3-6', label: '3-6 months' },
  { key: 'month-6-9', label: '6-9 months' },
  { key: 'month-9-12', label: '9-12 months' },
  { key: 'month-12-18', label: '12-18 months' },
  { key: 'month-18-24', label: '18-24 months' },
  { key: 'age-2-3', label: '2-3 years', wear: true },
  { key: 'age-2-4', label: '2-4 years' },
  { key: 'age-3-4', label: '3-4 years', wear: true },
  { key: 'age-4-6', label: '4-6 years' },
  { key: 'age-4-5', label: '4-5 years', wear: true },
  { key: 'age-5-6', label: '5-6 years', wear: true },
  { key: 'age-6-7', label: '6-7 years', wear: true },
  { key: 'age-6-8', label: '6-8 years' },
  { key: 'age-7-8', label: '7-8 years', wear: true },
  { key: 'age-8-9', label: '8-9 years', wear: true },
  { key: 'age-8-10', label: '8-10 years' },
  { key: 'age-9-10', label: '9-10 years', wear: true },
  { key: 'age-10-11', label: '10-11 years', wear: true },
  { key: 'age-10-12', label: '10-12 years' },
  { key: 'age-11-12', label: '11-12 years', wear: true },
];

export const options = [
  { key: 'new', label: 'NEW', description: 'Original packaging or tags' },
  { key: 'like_new', label: 'AS NEW', description: 'Used a couple of times' },
  { key: 'used', label: 'GREAT', description: 'Minimal signs of wear/tear' },
  { key: 'used_fair', label: 'GOOD', description: 'Works, signs of wear/tear' },
  { key: 'used_poor', label: 'FAIR', description: 'Heavily used' },
];

export const pricingOptions = [{ key: 'yes', label: 'YES' }, { key: 'no', label: 'NO' }];

export const amenities = categories.map(c => c.amenities).flat();

export const filterAmenities = category => categories.filter(c => category === c.key);
export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: categories,
    },
  },
  {
    id: 'ages',
    label: 'Ages',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: ages,
    },
  },
  {
    id: 'amenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: amenities,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
