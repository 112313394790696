import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import classNames from 'classnames';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    nextImage,
    indexImage,
  } = props;

  const index = indexImage <= listing.images.length ? indexImage : 0;
  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[index] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['scaled-medium']}
          />
          {viewPhotosButton}
        </div>
      </div>
      {/* This is the markup for image carousel pagination */}
      <div className={css.carouselNavigation}>
        <a
          href="#"
          className={css.arrow}
          onClick={() => nextImage('back', hasImages && listing.images.length)}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 27"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.803,1.803l-12,11.5l12,11.5"
              fill="none"
              stroke="#dead2c"
              strokeWidth="2px"
            />
          </svg>
        </a>
        {hasImages &&
          listing.images.map((item, i) => (
            <a key={i} href="#" className={classNames(css.dot, i === index && css.dotActive)}></a>
          ))}
        <a href="#" className={css.arrow} onClick={() => nextImage('next', listing.images.length)}>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 27"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.803,1.803l12,11.5l-12,11.5"
              fill="none"
              stroke="#dead2c"
              strokeWidth="2px"
            />
          </svg>
        </a>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
