import React, { useState } from 'react';
import { node } from 'prop-types';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import ContactUsForm from '../../forms/ContactUsForm/ContactUsForm';
import { TopbarContainer } from '../../containers';

import css from './ContactUsPage.css';

const ContactUsPageComponent = props => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const { children, ...pageProps } = props;

  const onSubmit = async data => {
    setUpdateInProgress(true);

    fetch('/sendemailtest', {
      method: 'post',
      body: JSON.stringify(data),
      headers: { 'Content-type': 'application/json' },
    })
      .then(response => response.json())
      .then(response => {
        const { status } = response;

        setUpdateInProgress(false);

        status === 200 && setSuccess(true);
        status !== 200 && setFailed(true);
      });
  };

  return (
    <Page title="Contact Us" {...pageProps}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.container}>
          <h1 className={css.title}>
            <FormattedMessage id="ContactUsPage.title" />
          </h1>
          <p>
            <FormattedMessage id="ContactUsPage.description" /> <br />
            <FormattedMessage id="ContactUsPage.descriptionNext" />
          </p>
          <ContactUsForm
            onSubmit={onSubmit}
            updateInProgress={updateInProgress}
            success={success}
            failed={failed}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ContactUsPageComponent.defaultProps = {
  children: null,
};

ContactUsPageComponent.propTypes = {
  children: node,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const StaticPage = connect(mapStateToProps)(ContactUsPageComponent);

export default StaticPage;
