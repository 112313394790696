import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, Heart } from '../../components';
import IconMapMarker from './IconMapMarker';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingCard.css';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return {
      formattedPrice: price.amount > 0 ? formattedPrice : 'Free',
      priceTitle: formattedPrice,
    };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    dontClickSold,
    currentUser,
    pressFavorite,
    isPressFavorite,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { publicData } = currentListing && currentListing.attributes;

  const productType = publicData && publicData.type;
  const address = publicData && publicData.location && publicData.location.address;

  const isFavorite =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.favorites &&
    currentUser.attributes.profile.publicData.favorites.includes(currentListing.id.uuid);

  const addressMaybe =
    address && (address.indexOf(',') != -1 ? address.split(',') : address.split(' '));
  let addressText = addressMaybe && addressMaybe.length ? addressMaybe[0] : address;

  const condition = (publicData && publicData.condition) || '';
  const sold = publicData && publicData.sold;
  const sale = publicData && publicData.sale;
  const oldPrice = publicData && publicData.oldPrice;

  const { formattedPrice, priceTitle } =
    price && price.amount ? priceData(price, intl) : priceData(new Money(0, config.currency), intl);
  const formattedOldPrice =
    sale && oldPrice && priceData(new Money(oldPrice.amount, config.currency), intl);

  const conditionOption = config.custom.options.filter(item => item.key === condition);
  const conditionLabel = conditionOption && conditionOption.length > 0 && conditionOption[0].label;
  const conditionTitle =
    conditionLabel &&
    `${conditionLabel.charAt(0).toUpperCase()}${conditionLabel.slice(1).toLocaleLowerCase()}`;

  const onClickHeart = e => {
    e.preventDefault();
    e.stopPropagation();

    pressFavorite(isFavorite, currentListing.id);
  };

  return (
    <NamedLink
      className={classes}
      name={dontClickSold ? 'SearchPage' : 'ListingPage'}
      params={{ id, slug }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['scaled-medium']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.priceValue} title={priceTitle}>
            {formattedOldPrice && (
              <span className={css.oldPriceValue} title={formattedOldPrice.priceTitle}>
                {formattedOldPrice.formattedPrice}
              </span>
            )}
            {formattedPrice}
          </div>
        </div>
        <div className={css.price}>
          <div className={css.locationInfo}>
            {sold ? (
              <div className={css.sold}>{intl.formatMessage({ id: 'ListingCard.sold' })}</div>
            ) : (
              <>
                {sale && (
                  <div className={css.sale}>{intl.formatMessage({ id: 'ListingCard.sale' })}</div>
                )}
                <IconMapMarker />
                {/* TODO: Replace with actual location */}
                <p className={css.address}>{addressText && addressText.split(' ')[0]}</p>
              </>
            )}
          </div>
          <div className={css.newItem}>
            {/* TODO: Replace with actual status */}
            {conditionTitle}
          </div>
        </div>
        {currentUser && (
          <div className={css.heart}>
            <Heart
              isSelected={isPressFavorite[id] !== undefined ? isPressFavorite[id] : isFavorite}
              onClick={onClickHeart}
            />
          </div>
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
