/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = currentTab => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const ManageListingsSideNavComponent = props => {
  const { currentTab, viewport } = props;

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  const tabs = [
    {
      text: 'Open',
      selected: currentTab === 'open',
      id: 'OpenListingsPageTab',
      linkProps: {
        name: 'OpenListingsPage',
      },
    },
    {
      text: 'Sold',
      selected: currentTab === 'sold',
      id: 'SoldListingsPageTab',
      linkProps: {
        name: 'SoldListingsPage',
      },
    },
    {
      text: 'Draft',
      selected: currentTab === 'draft',
      id: 'DraftListingsPageTab',
      linkProps: {
        name: 'DraftListingsPage',
      },
    },
    {
      text: 'Closed',
      selected: currentTab === 'closed',
      id: 'ClosedListingsPageTab',
      linkProps: {
        name: 'ClosedListingsPage',
      },
    }
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

ManageListingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

ManageListingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const ManageListingsSideNav = compose(withViewport)(
  ManageListingsSideNavComponent
);

export default ManageListingsSideNav;
