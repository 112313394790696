import React from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  menuFirstFooterColumn,
  menuSecondFooterColumn,
  menuThirdFooterColumn,
} from '../../ducks/ghost.duck';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [instragramLink].filter(v => v != null);
};

const FooterComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    showLogo,
    pagesFirstfooterColumn,
    pagesSecondFooterColumn,
    pagesThirdFooterColumn,
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const logo = showLogo ? (
    <div className={css.organization} id="organization">
      <NamedLink name="LandingPage" className={css.logoLink}>
        <Logo format="desktop" className={css.logo} />
      </NamedLink>
    </div>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            {logo}
            <div className={classNames(css.infoLinks, css.firstInfoLinks)}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="SearchPage">
                    <FormattedMessage id="Footer.buyPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    name="NewListingPage"
                    to={{ state: { type: 'sell' } }}
                  >
                    <FormattedMessage id="Footer.sellPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    name="NewListingPage"
                    to={{ state: { type: 'give' } }}
                  >
                    <FormattedMessage id="Footer.givePage" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.infoLinks}>
              <ul className={css.list}>
                {pagesFirstfooterColumn.map(page => (
                  <li className={css.listItem} key={page.slug}>
                    <NamedLink name="GhostPage" params={{ slug: page.slug }} className={css.link}>
                      {page.title}
                    </NamedLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className={css.infoLinks}>
              <ul className={css.list}>
                {pagesSecondFooterColumn.map(page => (
                  <li className={css.listItem} key={page.slug}>
                    <NamedLink
                      name="GhostPage"
                      params={{
                        slug: page.slug === 'terms-of-use' ? 'terms-of-service' : page.slug,
                      }}
                      className={css.link}
                    >
                      {page.title}
                    </NamedLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className={css.infoLinks}>
              <ul className={css.list}>
                {pagesThirdFooterColumn.map(page => (
                  <li className={css.listItem} key={page.slug}>
                    <NamedLink name="GhostPage" params={{ slug: page.slug }} className={css.link}>
                      {page.title}
                    </NamedLink>
                  </li>
                ))}
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="ContactUsPage">
                    <FormattedMessage id="Footer.contactUsPage" />
                  </NamedLink>
                </li>
              </ul>

              {socialMediaLinks}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ghostPages } = state.ghost;

  return {
    pagesFirstfooterColumn: menuFirstFooterColumn(ghostPages),
    pagesSecondFooterColumn: menuSecondFooterColumn(ghostPages),
    pagesThirdFooterColumn: menuThirdFooterColumn(ghostPages),
  };
};

const mapDispatchToProps = dispatch => ({});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const Footer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FooterComponent);

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape,
  showLogo: bool,
};

export default injectIntl(Footer);
