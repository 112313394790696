import React from 'react';

import css from './ListingCard.css';

const IconMapMarker = () => (
  <svg className={css.mapMarkerIcon} width="15" height="20" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1,0,0,1,-6.39994,-15.9998)">
      <g transform="matrix(20.0023,0,0,20.0023,3.73295,36.0022)">
        <path
          d="M0.5,-1C0.298,-1 0.133,-0.827 0.133,-0.617C0.133,-0.329 0.443,-0.03 0.443,-0.03C0.484,0.008 0.509,0.011 0.554,-0.03C0.554,-0.03 0.867,-0.329 0.867,-0.617C0.867,-0.827 0.702,-1 0.5,-1ZM0.5,-0.46C0.404,-0.46 0.327,-0.538 0.327,-0.633C0.327,-0.729 0.404,-0.807 0.5,-0.807C0.596,-0.807 0.674,-0.729 0.674,-0.633C0.674,-0.538 0.596,-0.46 0.5,-0.46Z"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default IconMapMarker;
