import React from 'react';

import css from './ListingPage.css';
import IconMapMarker from '../../components/ListingCard/IconMapMarker';

const SectionFeaturesMaybe = props => {
  const { options, publicData, postagePrice, isGive } = props;

  if (!publicData) {
    return null;
  }

  const { brand, location, color, size, age, collectionInPerson, collection } = publicData;

  const isCollectionAnObject = collection && typeof collection === 'object';
  const isCollectionInPersonAnObject = collectionInPerson && typeof collectionInPerson === 'object';

  const isCollectionOnly =
    collection === 'collection' ||
    !!(isCollectionAnObject ? collection && collection.length : collection === 'collection');

  const isPostageOnly =
    collection === 'postage' ||
    (isCollectionInPersonAnObject
      ? !isCollectionOnly && !(collectionInPerson && collectionInPerson.length)
      : collection === 'postage');

  const isCollectionAndPostage =
    collection === 'collection-and-postage' || (!isCollectionOnly && !isPostageOnly);

  return (
    <div className={css.sectionFeatures}>
      {/* <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      /> */}
      <p className={css.featuresItem}>
        <strong>Brand: </strong>
        {brand}
      </p>
      <p className={css.featuresItem}>
        <strong>Colour: </strong>
        {color}
      </p>
      <p className={css.featuresItem}>
        <strong>Size: </strong>
        {size}
      </p>
      <p className={css.featuresItem}>
        <strong>Age: </strong>
        {age}
      </p>
      <p className={css.featuresItem}>
        <br />
        {isCollectionAndPostage ? (
          <span className={css.collection}>Collection in person available</span>
        ) : null}
        {isPostageOnly || isCollectionAndPostage ? (
          <>
            <strong>Postage: </strong>
            {postagePrice}
          </>
        ) : null}
      </p>
      {isCollectionOnly ? <p className={css.featuresItem}>Collection in person only</p> : null}
      {isCollectionOnly || isCollectionAndPostage ? (
        <div className={css.pinContainer}>
          <IconMapMarker />
          <p className={css.featuresItem}>{location.address}</p>
        </div>
      ) : null}
      <p className={css.returns}>Returns not accepted</p>
    </div>
  );
};

export default SectionFeaturesMaybe;
