import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentSearchParams,
    scrollable,
    type,
    location,
    currentProductType,
  } = props;

  const { pathname, state } = location;
  const productType = (state && state.type) || currentProductType;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentSearchParams={currentSearchParams}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={
        productType === 'inbox'
          ? classNames(css.linkActive, css.basicLink, css.inboxContainer)
          : classNames(css.basicLink, css.inboxContainer)
      }
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      to={{ state: { type: 'inbox' } }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="OpenListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OpenListingsPage'))}
            name="OpenListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyFavoritePage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('MyFavoritePage'))}
            name="MyFavoritePage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.favourites" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={classNames({ [css.fixedMenu]: !scrollable })}>
        <div className={css.navWrapperContainer}>
          <div className={css.navWrapperTop}>
            <div className={css.navWrapper}>
              <NamedLink
                className={
                  pathname === '/s' ? classNames(css.linkActive, css.basicLink) : css.basicLink
                }
                name="SearchPage"
              >
                <span className={css.basicLinkInner}>Buy</span>
              </NamedLink>
              <NamedLink
                className={
                  productType === 'sell' ? classNames(css.linkActive, css.basicLink) : css.basicLink
                }
                name="NewListingPage"
                to={{ state: { type: 'sell' } }}
              >
                <span className={css.basicLinkInner}>Sell</span>
              </NamedLink>
              <NamedLink
                className={
                  productType === 'give' ? classNames(css.linkActive, css.basicLink) : css.basicLink
                }
                name="NewGiveListingPage"
                to={{ state: { type: 'give' } }}
              >
                <span className={css.basicLinkInner}>Give</span>
              </NamedLink>
              <NamedLink
                className={
                  productType === 'octoplus' ? classNames(css.linkActive, css.basicLink) : css.basicLink
                }
                name="OctoplusPage"
                to={{ state: { type: 'octoplus' } }}
              >
                <span className={css.basicLinkInner}>Octoplus</span>
              </NamedLink>
              <NamedLink
                className={
                  productType === 'how-it-works'
                    ? classNames(css.linkActive, css.basicLink)
                    : css.basicLink
                }
                to={{ state: { type: 'how-it-works' } }}
                name="GhostPage"
                params={{ slug: 'how-it-works' }}
              >
                <span className={css.basicLinkInner}>How it works</span>
              </NamedLink>
              <NamedLink
                className={
                  pathname === '/faqs' ? classNames(css.linkActive, css.basicLink) : css.basicLink
                }
                name="GhostPage"
                params={{ slug: 'faqs' }}
              >
                <span className={css.basicLinkInner}>FAQs</span>
              </NamedLink>
            </div>
          </div>
          <div className={css.navWrapperBottom}>
            <div className={css.navWrapper}>
              {search}
              <div
                className={classNames(
                  css.navButtonContainer,
                  authenticatedOnClientSide && css.navButtonContainerAuth
                )}
              >
                {inboxLink}
                {profileMenu}
                {signupLink}
                {loginLink}
              </div>
            </div>
          </div>
        </div>
        <div className={css.userNav}>{props.children}</div>
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentSearchParams: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  // currentSearchParams: object.isRequired,
};

export default TopbarDesktop;
