import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import HeroSubtitle from '../../components/SectionHero/HeroSubtitle';

import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, intl, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <video
        className={css.heroVideo}
        src="/static/octopus_optimised.mp4"
        playsInline
        autoPlay
        loop
        muted
      >
        Sorry, your browser does not support this video player.
      </video>
      <HeroSubtitle intl={intl} classes={css.subTitle} />
      <p className={css.heroDescription}>
        {isMobileLayout ? (
          <>
            <FormattedMessage id="SectionHero.descriptionMobile" /> <br />
            <FormattedMessage id="SectionHero.descriptionMobileLineTwo" /> <br />
            <FormattedMessage id="SectionHero.descriptionMobileLineThree" /> <br />
            <FormattedMessage id="SectionHero.descriptionMobileLineFour" /> <br />
            <FormattedMessage id="SectionHero.descriptionMobileLineFive" /> <br />
            <FormattedMessage id="SectionHero.descriptionMobileLineSix" /> <br />
          </>
        ) : (
          <>
            <FormattedMessage id="SectionHero.description" />{' '}
            <FormattedMessage id="SectionHero.descriptionLineTwo" />{' '}
            <FormattedMessage id="SectionHero.descriptionLineThree" />
          </>
        )}
      </p>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
