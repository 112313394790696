import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionCta,
  SectionRecentlyListed,
  SectionCtaColumns,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionRecentlySold,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { loadData } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import classNames from 'classnames';

import facebookImage from '../../assets/The_Octopus_Club-01.png';
import twitterImage from '../../assets/The_Octopus_Club-01.png';
import css from './LandingPage.css';

/* Images for CTAs */
import Cta1Image from './take-me-to-play.png';
import Cta2Image from './invite-your-friends.jpg';

const MAX_MOBILE_SCREEN_WIDTH = 768;

// export const LandingPageComponent = props =>
export class LandingPageComponent extends Component {
  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      recentlyListedListings,
      isAuthenticated,
      viewport,
      fetchRecentlySoldListingsInProgress,
      fetchRecentlySoldListingsError,
      recentlySoldListings,
    } = this.props;
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    console.log('recentlySoldListings', recentlySoldListings);
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const recentlySoldTitle = intl.formatMessage({
      id: 'LandingPage.recentlySold',
    });

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
        classNameContent={isMobileLayout && css.pageContent}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar className={css.mobileViewHeader}>
            <TopbarContainer hideLogo scrollable />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={classNames(css.heroContainer, css.mobileView)}>
              <SectionHero
                isMobileLayout={isMobileLayout}
                className={css.hero}
                history={history}
                location={location}
                intl={intl}
              />
            </div>
            <ul className={css.sections}>
              <li className={classNames(css.section, css.mobileView)}>
                <div className={css.sectionContentFirstChild}>
                  <SectionRecentlyListed
                    className={css.recentlyListed}
                    listings={recentlyListedListings}
                    history={history}
                  />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionCta
                    title="Refresh your baby’s toys to help develop new motor skills"
                    buttonTitle="Take me to play"
                    image={Cta1Image}
                    flipped="true"
                    linkName="SearchPage"
                    search="?pub_category=play"
                  />
                </div>
              </li>
              <li className={classNames(css.section, css.mobileView)}>
                <div className={css.sectionContent}>
                  <SectionCtaColumns isAuthenticated={isAuthenticated} />
                </div>
              </li>
              {!isMobileLayout && (
                <li className={classNames(css.section, css.mobileView)}>
                  <div className={css.sectionContentFirstChild}>
                    <SectionRecentlySold
                      className={css.recentlyListed}
                      listings={recentlySoldListings}
                      title={recentlySoldTitle}
                      history={history}
                    />
                  </div>
                </li>
              )}
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionCta
                    title="Secondhand clothes don't need to be boring"
                    buttonTitle="TAKE ME TO WEAR"
                    image={Cta2Image}
                    flipped={isMobileLayout ? 'true' : 'false'}
                    history={history}
                  />
                </div>
              </li>
              {isMobileLayout && (
                <li className={classNames(css.section, css.mobileView)}>
                  <div className={css.sectionContentFirstChild}>
                    <SectionRecentlySold
                      className={css.recentlyListed}
                      listings={recentlySoldListings}
                      title={recentlySoldTitle}
                      history={history}
                    />
                  </div>
                </li>
              )}
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter className={css.mobileView}>
            <Footer showLogo={isMobileLayout ? false : true} intl={intl} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape,

  // from withViewport
  viewport: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const {
    loadInProgress,
    loadListingsError,
    recentlyListedListings,
    recentlySoldListings,
  } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    loadInProgress,
    loadListingsError,
    recentlyListedListings,
    isAuthenticated,
    recentlySoldListings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
