import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import {updateOptInOptions} from "../../util/api";

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';
export const SAVE_OPT_IN_ERROR = 'app/ContactDetailsPage/SAVE_OPT_IN_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveOptInError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        saveOptInError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };
    case SAVE_OPT_IN_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveOptInError: payload };

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        saveOptInError: null,
        contactDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = error => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});
export const saveOptInError = error => ({
  type: SAVE_OPT_IN_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumberAndOptIn = params => (dispatch, getState, sdk) => {
  const phoneNumber = params.phoneNumber ? params.phoneNumber : getState().user.currentUser.attributes.profile.protectedData && getState().user.currentUser.attributes.profile.protectedData.phoneNumber ? getState().user.currentUser.attributes.profile.protectedData.phoneNumber : '';

  const optIn = params.optIn !== undefined ? params.optIn : getState().user.currentUser.attributes.profile.protectedData && getState().user.currentUser.attributes.profile.protectedData.optIn ? getState().user.currentUser.attributes.profile.protectedData.optIn : ''

  console.log('saveContactDetails: ', optIn)

  return sdk.currentUser
    .updateProfile(
      { protectedData: { phoneNumber, optIn: optIn } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];

      updateOptInOptions().then(result => {
        console.log('sent to mailchimp successfully: ', {result})
      }).catch(e => {
        console.log('error sending to mailchimp: ', {e})
      })

      return currentUser;
    })
    .catch(e => {
      dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a opt in update request to the API and return the current user.
 */
const requestSaveOptIn = params => (dispatch, getState, sdk) => {
  const optIn = params.optIn;

  console.log('sdk.currentUser: ', getState().user.currentUser)

  console.log('sdk.currentUser: ', getState().user.currentUser.attributes.profile.protectedData && getState().user.currentUser.attributes.profile.protectedData.phoneNumber ? getState().user.currentUser.attributes.profile.protectedData.phoneNumber : '')

  const phoneNumber = getState().user.currentUser.attributes.profile.protectedData && getState().user.currentUser.attributes.profile.protectedData.phoneNumber ? getState().user.currentUser.attributes.profile.protectedData.phoneNumber : ''

  return sdk.currentUser
    .updateProfile(
      { protectedData: { optIn, phoneNumber } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveOptInError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a email update request to the API and return the current user.
 */
const requestSaveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save email and update the current user.
 */
const saveEmail = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
      })
      // error action dispatched in requestSaveEmail
      .catch(e => null)
  );
};

/**
 * Save phone number and update the current user.
 */
// const savePhoneNumber = params => (dispatch, getState, sdk) => {
//   return (
//     dispatch(requestSavePhoneNumber(params))
//       .then(user => {
//         dispatch(currentUserShowSuccess(user));
//         dispatch(saveContactDetailsSuccess());
//       })
//       // error action dispatched in requestSavePhoneNumber
//       .catch(e => null)
//   );
// };

/**
 * Save opt in and update the current user.
 */
const saveOptIn = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveOptIn(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};

/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndPhoneNumberAndOptIn = params => (dispatch, getState, sdk) => {
  const { email, phoneNumber, optIn, currentPassword, emailChanged, phoneNumberChanged, optInChanged } = params;

  console.log('saveContactDetails: ', optIn);

  const changes = [emailChanged, phoneNumberChanged, optInChanged];

  // order of promises: 1. email, 2. phone number 3. opt in
  const promises = [
    emailChanged && dispatch(requestSaveEmail({ email, currentPassword })),
    (phoneNumberChanged || optInChanged) && dispatch(requestSavePhoneNumberAndOptIn({ phoneNumber, optIn })),
    // optInChanged && dispatch(requestSaveOptIn({ optIn })),
  ];


  console.log('promises: ', {promises});


  return Promise.all(promises)
    .then(values => {


      const updatedValues = values.filter(val => val)

      console.log('promises updatedvalues: ', {updatedValues})


      const mergedValues = updatedValues.map((upVal, i) => {

          if (i === 0) {
            return upVal
          } else {
            const protectedData = upVal.attributes.profile.protectedData;
            return { attributes: { profile: { protectedData } } };
          }


      })


      console.log('mergedValues: ', ...mergedValues)

      // const saveEmailUser = values[0];
      // const savePhoneNumberUser = values[1];
      // const saveOptInUser = values[2];
      //
      // // merge the protected data from the user object returned
      // // by the phone update operation
      // const protectedData = savePhoneNumberUser.attributes.profile.protectedData;
      // const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };
      //
      // // merge the protected data from the user object returned
      // // by the optIn update operation
      // const optInProtectedData = saveOptInUser.attributes.profile.protectedData;
      // const optInMergeSource = { attributes: { profile: { protectedData: optInProtectedData } } };
      //
      // console.log('phoneNumber: ', {phoneNumberMergeSource})
      // console.log('optIn: ', {optInMergeSource})

      const currentUser = merge(...mergedValues);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess());
    })
    .catch(e => null);
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  console.log('saveContactDetails: ', {params});

  const { email, currentEmail, phoneNumber, optIn, currentPhoneNumber, currentOptIn, currentPassword } = params;
  const emailChanged = email !== currentEmail;
  const phoneNumberChanged = phoneNumber !== currentPhoneNumber;
  const optInChanged = optIn !== currentOptIn;

  if (emailChanged || phoneNumberChanged || optInChanged) {
    return dispatch(saveEmailAndPhoneNumberAndOptIn({ email, currentPassword, phoneNumber, optIn, emailChanged, phoneNumberChanged, optInChanged }));
  }
  // else if (emailChanged) {
  //   return dispatch(saveEmail({ email, currentPassword }));
  // } else if (phoneNumberChanged) {
  //   return dispatch(savePhoneNumber({ phoneNumber }));
  // } else if (optInChanged) {
  //   return dispatch(saveOptIn({ optIn }));
  // }
};
