import React from 'react';
import { Button, NamedLink } from '../../components';
import css from './CtaBanner.css';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

const navigateToWear = props => {
  const { history } = props;
  const queryParams = {
    pub_category: 'wear',
  };

  history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
};

const CtaBanner = props => {
  const bannerClass = props.flipped === 'true' ? css.bannerFlipped : css.banner;
  const search = props && props.search;
  const ctaButton = props.linkName ? (
    <NamedLink className={css.button} name={props.linkName} to={{ search }}>
      <span>{props.buttonTitle}</span>
    </NamedLink>
  ) : (
    <Button className={css.button} onClick={() => navigateToWear(props)}>
      {props.buttonTitle}
    </Button>
  );

  return (
    <div className={bannerClass}>
      <div className={css.bannerInner}>
        <div className={css.title}>{props.title}</div>

        {ctaButton}
      </div>
    </div>
  );
};

export default CtaBanner;
