import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  ListingCard,
  PaginationLinks,
  LayoutWrapperFooter,
  Footer,
  Button,
  NamedLink,
} from '../../components';
import css from './SearchResultsPanel.css';

import { intlShape, injectIntl } from '../../util/reactIntl';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    shownProdCount,
    setShownProdCount,
    getCurrentRef,
    reRenderButton,
    history,
    intl,
    currentUser,
    pressFavorite,
    isPressFavorite,
    hideFooter,
  } = props;
  const myRef = useRef(null);
  const classes = classNames(rootClassName || css.root, className);
  const { page, totalPages } = pagination || {};
  const nextPage = page < totalPages ? page + 1 : null;

  const listingsCount = listings.length;
  const showLoadMore = !(shownProdCount >= listingsCount) || (nextPage && nextPage > 1);
  const loadNextPage = nextPage && nextPage > 1 && shownProdCount >= listingsCount;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const { state } = history.location;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const clickSoldListing = (sold, category) => {
    if (sold) {
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { pub_category: category }
        ),
        {
          sold: true,
        }
      );
    }
  };

  return (
    <div className={classes} ref={myRef} onLoad={getCurrentRef(myRef)}>
      {state && state.sold && (
        <div className={css.soldAlertMainContainer}>
          <div className={css.soldContainer}>
            {intl.formatMessage(
              { id: 'ListingCard.alreadySold' },
              { link: <NamedLink name="ContactUsPage">contact us</NamedLink> }
            )}
          </div>
        </div>
      )}
      <div className={css.listingCards}>
        {listings.map((l, i) => {
          if (!(i + 1 > shownProdCount)) {
            const { sold, category } = l.attributes.publicData;
            return (
              <div
                className={css.listingContainer}
                onClick={() => clickSoldListing(sold, category[0])}
              >
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                  history={history}
                  dontClickSold={sold}
                  currentUser={currentUser}
                  pressFavorite={pressFavorite}
                  isPressFavorite={isPressFavorite}
                />
              </div>
            );
          }
        })}
        {props.children}
      </div>
      {paginationLinks}
      {!hideFooter && (
        <LayoutWrapperFooter className={css.searchFooter}>
          <Footer className={css.searchFooterInner} />
        </LayoutWrapperFooter>
      )}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default injectIntl(SearchResultsPanel);
